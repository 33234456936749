import React, { useContext, useEffect, useState, useCallback } from "react";
import { Tabs } from "flowbite-react";
import Header from "../header/header";
import { UserContext } from "../context/UserContext";
import { useSnap } from "raeditor/canvas/use-transformer-snap";
import { getUserCreditsAndTemplates } from "../APIservice/apiService";

const DashboardTab = () => {
  const { UserName, updateUser } = useContext(UserContext);
  const [credits, setCredits] = useState(UserName.credits);
  const [totalCredits, setTotalCredits] = useState(0);
  const [plan, setPlan] = useState(UserName.plan);
  const [response, setResponse] = useState(UserName.credits);
  const [loading, setLoading] = useState(true); // Initialize loading stat

  const fetchTemplates = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUserCreditsAndTemplates();
      if (response) {
        console.log(response);
      }

      console.log(response, "templateresponse");
      setResponse(response);
      setCredits(response.remainingCredits);
      setPlan(response.planName);
      setTotalCredits(response.totalPlanCredits);
      setLoading(false);
    } catch (error) {
      // console.error("Fetching templates failed:", error);
      // console.log(error)
      // if(error.response.data == "Project not found"){
      //   navigate(`/projects`);
      // }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image

  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the selected image
  };

  const closeModal = () => {
    setSelectedImage(null); // Close the modal
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-6">
        <div className="flex justify-between items-center mx-auto py-6 px-0 sm:px-6 lg:px-8 dashboard_h2">
          <h2 className="font-bold text-black-900 leading-tight">
            Dashboard
          </h2>
        </div>
        <div className="mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 gap-x-5 mb-6">
            <a
              href="#"
              className="block max-w-xl p-6 bg-white rounded-lg shadow-lg dark:bg-white-300 dark:border-white-700 dark:hover:bg-white-700"
            >
              <p className="font-bold text-black-900 dark:text-black-900 mb-2">
                AVAILABLE CREDITS
              </p>
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-black-600 dark:text-white inline-flex items-center align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 mr-2 text-black dark:text-black"
                  fill="currentColor"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M360-400h400L622-580l-92 120-62-80-108 140Zm-40 160q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"></path>
                </svg>
                {/*{credits} / {totalCredits}*/}
                {loading ? (
                  <div className="h-5 w-24 bg-white-700 rounded animate-pulse"></div>
                ) : (
                  <span className="font-semibold text-black">
                    {credits > totalCredits
                      ? `${totalCredits} / ${totalCredits}`
                      : `${credits} / ${totalCredits}`}
                  </span>
                )}
              </h5>
            </a>
            <a
              href="#"
              className="block max-w-xl p-6 bg-white rounded-lg shadow-lg dark:bg-white-800 "
            >
              <p className="font-bold text-black dark:text-black-900 mb-2">
                YOUR PLAN
              </p>
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-600 dark:text-black inline-flex items-center align-middle">
                <svg
                  className="w-8 h-8 mr-2 text-black dark:text-black"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z"></path>
                </svg>

                {loading ? (
                  <div className="h-5 w-24 bg-black rounded animate-pulse"></div>
                ) : (
                  <span className="font-semibold text-black">
                    {plan}
                  </span>
                )}
              </h5>
            </a>
            {/*  <a
              href="#"
              className="block max-w-sm p-6 bg-white rounded-lg shadow-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <p className="font-normal text-gray-700 dark:text-gray-400 mb-2">
                NEXT BILLING PERIOD
              </p>
              <h5 className="mb-2 text-4xl font-bold tracking-tight text-gray-600 dark:text-white inline-flex items-center align-middle">
                -
              </h5>
            </a>*/}
          </div>
          <div className="block p-6 bg-white rounded-lg shadow-lg ">
            <h5 className="mb-3 text-xl font-bold tracking-tight text-black inline-flex items-center align-middle">
              USAGE
            </h5>
            <div className="w-full h-4 bg-[#83c5ee] rounded-full">
              {loading ? (
                <div className="h-4 w-full bg-sky rounded-full animate-pulse"></div>
              ) : (
                <>
                <div
                  className="h-4 bg-gradient-to-br from-[#4c6ee2] to-[#4c6ee2] rounded-full"
                  // style={{ width: `${(credits - totalCredits) * 100}%` }}
                  // style={{ width: `${((totalCredits - credits) / totalCredits) * 100}%` }} // Calculate remaining credits percentage
                  //   style={{width: `${totalCredits - credits}%`}}
                  style={{
                    width: `${((totalCredits - credits) / totalCredits) * 100}%`,
                    // width: `${((totalCredits - credits) / 100) * 100}%`,
                    

                  }} // Calculate remaining credits percentage
                ></div>
               
                  </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mx-auto py-6 px-0 sm:px-6 lg:px-8 dashboard_h2">
          <h2 className="font-bold text-black-900 leading-tight text-4xl">
            Your Dynamic Generated Images
          </h2>
        </div>

        <div className="mx-auto sm:px-6 lg:px-8 ">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden block " >

            {/* Table Container */}
            <div className="overflow-x-auto">
              <table className="w-full ">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-4 text-lg font-semibold text-black-900 text-center">#</th>
                    <th className="px-6 py-4 text-lg font-semibold text-black-900 text-center">Preview Image</th>
                    <th className="px-6 py-4 text-lg font-semibold text-black-900 text-center">Template Name</th>
                    <th className="px-6 py-4 text-lg font-semibold text-black-900 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {loading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index} className="bg-white">
                        <td className="px-6 py-4">
                          <div className="h-8 bg-gray-100 rounded animate-pulse mx-auto w-8"></div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="h-8 bg-gray-100 rounded animate-pulse mx-auto w-48"></div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-center space-x-3">
                            <div className="h-9 w-24 bg-gray-100 rounded-full animate-pulse"></div>
                            <div className="h-9 w-24 bg-gray-100 rounded-full animate-pulse"></div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : response?.templatesWithDownloadUrl?.length > 0 ? (
                    response.templatesWithDownloadUrl.map((template, index) => (
                      <tr key={template.template_id} className="bg-white hover:bg-gray-50 transition-colors">
                        <td className="px-6 py-4 text-center font-medium text-black-900">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 align-middle flex justify-center">
                          <img
                            onClick={() => handleImageClick(template?.image_url)}
                            src={template?.image_url}
                            className="w-16 h-16 cursor-pointer border border-gray-300 rounded-md m-auto"
                            alt="Template Preview"
                          />
                        </td>
                        <td className="px-6 py-4 text-center text-base text-black">
                          {template?.template_name}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-center items-center space-x-1">
                            <button
                              onClick={() => handleImageClick(template?.image_url)}
                              className="inline-flex items-center p-2 text-sm font-medium text-black bg-primary-light rounded-full hover:bg-black hover:text-white transition-colors duration-200"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </button>
                            <button
                              onClick={() => (window.location.href = template?.downloadUrl)}
                              className="inline-flex items-center p-2 text-sm font-medium text-black bg-primary-light rounded-full hover:bg-black hover:text-white transition-colors duration-200"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="px-6 py-8 text-center text-black-600">
                        <div className="flex flex-col items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-12 h-12 text-gray-300 mb-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 6h16M4 10h16M4 14h16M4 18h16"
                            />
                          </svg>
                          <p className="text-lg font-medium">No templates found</p>
                          <p className="text-sm text-black-600 mt-1">Start generating templates to see them here</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Card Footer - Optional, add if needed */}
            {/* <div className="px-6 py-4 border-t border-gray-100">
              <p className="text-sm text-black-600">
                Showing {response?.templatesWithDownloadUrl?.length || 0} templates
              </p>
            </div> */}
          </div>
        </div>

        {selectedImage && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            onClick={closeModal}
          >
            <div
              className="bg-white p-4 rounded relative "
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={closeModal}
                className="absolute top-1 right-1 p-1 text-black-700 bg-white rounded-full"
              >
                {/* Cross icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <img
                src={selectedImage}
                alt="Selected"
                className="max-w-full max-h-[80vh] w-auto object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardTab;
