import React from 'react';
import { WorkspaceCanvas } from 'raeditor/canvas/workspace-canvas';
import { Tooltip } from './tooltip';

interface WorkspaceProps {
    components?: {
        Tooltip?: typeof Tooltip;
    };
}

const Workspace: React.FC<WorkspaceProps> = ({
                                                 components = {},
                                                 ...restProps
                                             }) => {
    const finalComponents = {
        Tooltip: components.Tooltip || Tooltip,
    };

    return (
        <WorkspaceCanvas
            components={finalComponents}
            {...restProps}
        />
    );
};

export { Workspace };
export default Workspace;