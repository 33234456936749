import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'raeditor/utils/l10n';
import { uploadImage } from "../APIservice/apiService";
import api from '../APIservice/apiHelper';

let upscaleImageFunc = async (url) => {
  try {
    const { data } = await api.post('/photos/upscale', {
      image: url
    });
    return data.output;
  } catch (error) {
    console.error('Error upscaling image:', error);
    alert(t('error.upscale'));
    return url;
  }
};

let uploadNewImage = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const pngBlob = new Blob([blob], { type: "image/png" });
  const uploadResponse = await uploadImage(pngBlob);
  return uploadResponse.url;
}

export const setUpscaleImageFunc = (func) => {
  upscaleImageFunc = func;
};

export const UpscaleDialog = observer(({ isOpen, onClose, element }) => {
  const [currentSrc, setCurrentSrc] = useState(element.src);
  const [isLoading, setIsLoading] = useState(false);
  const hasChanges = currentSrc !== element.src;

  useEffect(() => {
    setCurrentSrc(element.src);
  }, [element.src]);

  const handleUpscale = async () => {
    setIsLoading(true);
    try {
      const newSrc = await upscaleImageFunc(currentSrc);
      setCurrentSrc(newSrc);
    } catch (error) {
      console.error('Failed to upscale:', error);
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    setCurrentSrc(element.src);
    onClose();
  };

  const handleConfirm = async () => {
    const newImageUrl = await uploadNewImage(currentSrc);
    element.set({ src: newImageUrl });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm"></div>
      <div className="relative w-[90%] max-w-2xl mx-auto">
        <div className="relative flex flex-col w-full bg-white rounded-lg shadow-xl">
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <h3 className="text-lg font-medium text-gray-900">
              {t('toolbar.upscaleTitle')}
            </h3>
            <button
              className="p-1 ml-auto text-gray-400 hover:text-gray-500"
              onClick={onClose}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Body */}
          <div className="p-6">
            <div className="overflow-hidden rounded-lg">
              <img
                src={currentSrc}
                className="w-full max-h-[60vh] object-contain bg-gray-50"
                alt="Preview"
              />
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end gap-3 px-6 py-4 border-t">
            {!hasChanges ? (
              <button
                onClick={handleUpscale}
                disabled={isLoading}
                className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="w-4 h-4 mr-2 animate-spin" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    {t('common.processing')}
                  </span>
                ) : (
                  t('toolbar.upscale')
                )}
              </button>
            ) : (
              <>
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {t('toolbar.cancelUpscale')}
                </button>
                <button
                  onClick={handleConfirm}
                  className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {t('toolbar.confirmUpscale')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export const ImageUpscale = observer(({ element }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <button
        className="px-3 py-1 text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-2"
        onClick={() => setIsDialogOpen(true)}
      >
        {t('toolbar.upscale')}
      </button>
      <UpscaleDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        element={element}
      />
    </>
  );
});
