import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineXCircle } from "react-icons/hi";
import { FaBeer } from "react-icons/fa";
import { RiMenuLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import { UserContext } from "../context/UserContext";

const navigationItems = [
  { name: "Dashboard", href: "/dashboard" },
  { name: "Projects", href: "/projects" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavButton({ item, location, onNavigate }) {
  const itemClass =
    location.pathname === item.href
      ? "bg-white text-black dark:hover:text-black"
      : "text-gray-100 hover:bg-white hover:text-black dark:hover:text-black ";

  return (
    <Link
      key={item.name}
      to={item.href}
      className={classNames(
        itemClass,
        "rounded-md px-3 py-2 text-sm font-medium hover:text-black "
      )}
      aria-current={item.current ? "page" : undefined}
      onClick={onNavigate}
    >
      {item.name}
    </Link>
  );
}

function UserMenu({ user, onSignOut }) {
  // State for controlling mobile menu visibility

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown
      }
    };

    // Add event listener for clicks outside of the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  // Function to handle menu item click and close the menu
  const handleMenuItemClick = () => {
    setIsOpen(false); // Close the menu when an option is clicked
  };
  return (
    <div className="relative ml-3 " ref={dropdownRef}>
      {/* Mobile Menu Button (User Icon for Mobile Screens) */}
      <button
        data-collapse-toggle="navbar-user"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm dark:text-white-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-user"
        aria-expanded="false"
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown on click
      >
        <span className="sr-only">Open user menu</span>
        {/* User profile icon */}
        <svg
          className="w-6 h-6 text-white dark:text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 12a5 5 0 100-10 5 5 0 000 10zm-7 8a7 7 0 0114 0H5z"
          />
        </svg>
      </button>

      {/* Mobile Dropdown Menu */}
      {isOpen && (
        <div className="absolute right-0 z-[99999999999] mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-white dark:text-black py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:hidden">
         
          <div className="grid grid-cols-1 divide-y divide-gray-500">
            <div className="mb-3 mt-2 ml-[17px] text-base text-black font-bold">
              {user ? user.name : "John"}
            </div>
           
          </div>
          <Link
            to="/profile"
            className="block px-4 py-2 text-sm text-black font-semibold "
            onClick={handleMenuItemClick} // Close menu when clicked
          >
            Your Profile
          </Link>
          <button
            className="block px-4 py-2 text-sm text-black font-semibold w-full text-left"
            onClick={() => {
              handleMenuItemClick();
              onSignOut();
            }} // Close menu and sign out
          >
            Sign out
          </button>
        </div>
      )}

      {/* Desktop Menu (Username and Dropdown) */}
      <Menu as="div" className="relative hidden md:block">
        <div>
          <MenuButton className="relative flex rounded-full dark:bg-white-800 text-sm focus:outline-none">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Open user menu</span>
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white-500 dark:text-black bg-white hover:text-gray-300 focus:outline-none transition ease-in-out duration-150"
            >
              {user ? user.name : "John"}
              <div className="ml-1">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          </MenuButton>
        </div>

        {/* Dropdown menu for Desktop */}
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 z-[99999999999] mt-2 w-48 origin-top-right rounded-md bg-white dark:text-black  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <MenuItem>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={`block px-4 py-2 text-sm text-black w-full text-left hover:text-white hover:bg-black   ${
                    active ? "text-white" : ""
                  }`}
                >
                  Your Profile
                </Link>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  className={`block px-4 py-2 text-sm text-black w-full text-left hover:text-white hover:bg-black  ${
                    active ? "bg-gray-800" : ""
                  }`}
                  onClick={onSignOut}
                >
                  Sign out
                </button>
              )}
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}

export default function Header() {
  const [user, setUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { UserName } = useContext(UserContext);

  useEffect(() => {
    const UserSession = Cookies.get("user");
    // const UserSession = sessionStorage.getItem('user');
    const currentUser = UserSession ? JSON.parse(UserSession) : {};
    // const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) {
      setUser(currentUser);
    }
  }, []);
  const handlePage = () => {
    // navigate("/dashboard");
    window.location.href = process.env.REACT_APP_REDIRECT_URL
    // window.location.href = "https://imageeazy.com/";
  };

  const signOutUser = () => {
    localStorage.clear();
    Cookies.remove("user", { domain: process.env.REACT_APP_TOKEN_DOMAIN, path: "/" });
    Cookies.remove("token", { domain: process.env.REACT_APP_TOKEN_DOMAIN, path: "/" });
    Cookies.remove("wp_token", { domain: process.env.REACT_APP_TOKEN_DOMAIN, path: "/" });
    Cookies.remove("wp_user", { domain: process.env.REACT_APP_TOKEN_DOMAIN, path: "/" });
    sessionStorage.clear();
    // sessionStorage.removeItem('user');
    // sessionStorage.removeItem('token')
    // sessionStorage.clear();

    navigate("/login");
  };

  return (
    <Disclosure as="nav" className="bg-black-900 border-b border-light-700">
      {({ open, close }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              {/* Menu Button for Mobile (Left aligned) */}
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 text-white dark:text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <IoMdClose className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <RiMenuLine className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>

              {/* Center the Logo */}
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div
                  onClick={handlePage}
                  className="flex-shrink-0 cursor-pointer"
                >
                  {/* <img
                    className="h-8 w-auto"
                    src="https://cdn.pixabay.com/photo/2017/02/15/00/48/logo-2067396_640.png"
                    alt="Workflow"
                  /> */}
                  <img
              src={require("../assets/1.png")}
              alt="logo"
              className="h-10 w-40 object-contain"
            />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4 text-white dark:text-white">
                    {navigationItems.map((item) => (
                      <NavButton item={item} location={location} />
                    ))}
                  </div>
                </div>
              </div>

              {/* User Menu (Right aligned for mobile) */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <UserMenu user={UserName} onSignOut={signOutUser} />
              </div>
            </div>

            {/* Disclosure panel for the mobile navigation items */}
            <DisclosurePanel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 text-white dark:text-white dark:hover:text-black">
                {navigationItems.map((item) => (
                  <NavButton item={item} location={location} 
                  onNavigate={() => {
                    navigate(item.href);
                    close(); // Close the mobile menu after navigation
                  }}/>
                ))}
              </div>
            </DisclosurePanel>
          </div>
        </>
      )}
    </Disclosure>
  );
}
