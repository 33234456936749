import { SectionTab } from "raeditor/side-panel";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from 'raeditor/utils/styled';
import {
  Button,
  HTMLSelect,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import RiImageAddFill from "@meronex/icons/ri/RiImageAddFill";
import { isAlive } from "mobx-state-tree";
import { getCrop } from "raeditor/utils/image";
import Cookies from "js-cookie";
import { uploadImage } from "../APIservice/apiService";
import { getImageSize } from "raeditor/utils/image";
import { ImagesGrid } from "raeditor/side-panel/images-grid";

export const AiImageGenrate = {
  name: "AI IMAGE",
  Tab: (props) => (
    <SectionTab name="AI IMAGE" {...props}>
      <RiImageAddFill className="w-5 h-5" />
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    const [prompt, setPrompt] = React.useState("");
    const [promptError, setPromptError] = useState(""); // New state for validation error
    const [selected, setSelected] = useState("1");

    const [selectRatio, setSelectRatio] = useState("1:1");
   
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [imageSize, setImageSize] = useState("512x512");

    const handleChange = (event) => {
      setSelected(event.target.value);
    };
    const handleChangeRatio = (event) => {
      setSelectRatio(event.target.value);
    };

    // Function to save images and their ratios to sessionStorage
    const saveImagesToSessionStorage = (imagesWithRatios) => {
      sessionStorage.setItem("savedImages", JSON.stringify(imagesWithRatios));
    };

    // Function to retrieve images and their ratios from sessionStorage
    const getImagesFromSessionStorage = () => {
      const UserSession = Cookies.get("user");
      if (!UserSession) {
        // If session doesn't exist, clear the image data from sessionStorage
        sessionStorage.removeItem("savedImages");
        sessionStorage.clear();
        return [];
      }

      // If session exists, retrieve the images and ratios
      const savedImagesWithRatios = sessionStorage.getItem("savedImages");
      return savedImagesWithRatios ? JSON.parse(savedImagesWithRatios) : [];
    };

    const validatePrompt = () => {
      if (!prompt.trim()) {
        setPromptError("Prompt cannot be empty");
        return false;
      }
      if (prompt.length < 3) {
        setPromptError("Prompt must be at least 3 characters long");
        return false;
      }
      setPromptError(""); // Clear error if validation passes

      return true;
    };

    // Function to generate images based on the provided prompt
    const ImageGenerate = async (prompt, selected) => {
      if (!validatePrompt()) return; // Prevent submission if validation fails

      setIsLoading(true);
      const API_ENDPOINT = `${process.env.REACT_APP_API_URL}api/photos/ai-image`;
      const token = Cookies.get("token", { domain: process.env.REACT_APP_TOKEN_DOMAIN });
      console.log(token);
      const selectedInt = parseInt(selected);
      const width = parseInt(imageSize.split("x")[0]);
      const height = parseInt(imageSize.split("x")[0]);
      const inputData = {
        input: {
          prompt: prompt,
          num_outputs: selectedInt,
          aspect_ratio: selectRatio,
          output_format: "webp",
          output_quality: 80,
          height: height,
          width: width,
        },
      };

      try {
        const response = await fetch(API_ENDPOINT, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData.input),
        });

        if (response.ok) {
          const data = await response.json();
          const newImages = data.output; // Array of image URLs

          // Call the file upload function to upload the images
          await handleFileUpload(newImages, selectRatio, width, height);
          setIsLoading(false);
        } else {
          console.error(`Error: ${response.statusText}`);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error calling API:", error);
        setIsLoading(false);
      }
    };

   

    // Function to handle the file upload along with ratio
    const handleFileUpload = async (imageUrls, ratio, width, height) => {
      setIsLoading(true); // Start loading

      try {
        const imagesWithRatios = []; // Initialize array to store URLs and their ratios

        // Loop through each image URL and upload
        for (const imageUrl of imageUrls) {
          const response = await fetch(imageUrl);
          const blob = await response.blob();

          // Create a new Blob with type `image/webp`
          const webpBlob = new Blob([blob], { type: "image/webp" });

          // Call the uploadImage function with the webpBlob
          const uploadResponse = await uploadImage(webpBlob);
          console.log("File upload successful:", uploadResponse);

          const newImageUrl = uploadResponse.url;

          // Store image URL with its ratio
          imagesWithRatios.push({
            url: newImageUrl,
            ratio: ratio,
            width: width,
            height: height,
          });
        }

        // Merge and update image URLs and their ratios in sessionStorage
        const updatedImages = [
          ...getImagesFromSessionStorage(),
          ...imagesWithRatios,
        ];
        saveImagesToSessionStorage(updatedImages);

        // Update state
        setImageUrls(updatedImages.map((image) => image.url)); // Keep only URLs in the state
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of success or failure
      }
    };

 

    // When loading the component
    useEffect(() => {
      const savedImages = getImagesFromSessionStorage();
      if (savedImages.length > 0) {
        setImageUrls(savedImages.map((image) => image.url)); // Only URLs in state
      }
    }, []);

    // Log imageUrls when they change
    useEffect(() => {
      console.log("ImageUrls:", imageUrls);
    }, [imageUrls]);

    

    const handleImageClick = async (url, pos, element) => {
      console.log(url, "URL IS HERE");

      // Fetch the image size (replace `getImageSize` with a suitable method if needed)
      const { width: imageWidth, height: imageHeight } = await getImageSize(
        url
      );
      console.log("Calling this function", imageWidth);
      console.log("Calling this function", imageHeight);

      // If an element exists and is editable, apply cropping and set the image
      if (element && element.type === "image" && element.contentEditable) {
        const crop = getCrop(element, {
          width: imageWidth,
          height: imageHeight,
        });
        element.set(Object.assign({ src: url }, crop));
        return; // End the function here since we've updated the existing element
      }

      // Retrieve saved image data from session storage
      const savedImages = getImagesFromSessionStorage();
      const savedImageData = savedImages.find((img) => img.url === url);

      let width, height, ratio;
      if (savedImageData) {
        // Use saved width, height, and ratio if available
        width = savedImageData.width;
        height = savedImageData.height;
        ratio = savedImageData.ratio; // Use the saved ratio
        const baseSize = store.height / 2; // Use half of the store's height as base size

        // Aspect ratio calculations based on selected ratio
        const ratio1 = parseInt(ratio.split(":")[0]); // e.g., 16
        const ratio2 = parseInt(ratio.split(":")[1]); // e.g., 9

        if (ratio1 >= ratio2) {
          // Calculate width based on height and aspect ratio
          height = baseSize;
          width = height * (ratio1 / ratio2);
        } else {
          // Calculate height based on width and aspect ratio
          width = baseSize;
          height = width * (ratio2 / ratio1);
        }
      } else {
        // Base size for images (adjust as necessary)
        const baseSize = store.height / 2; // Use half of the store's height as base size

        // Aspect ratio calculations based on selected ratio
        const ratio1 = parseInt(selectRatio.split(":")[0]); // e.g., 16
        const ratio2 = parseInt(selectRatio.split(":")[1]); // e.g., 9

        if (ratio1 >= ratio2) {
          // Calculate width based on height and aspect ratio
          height = baseSize;
          width = height * (ratio1 / ratio2);
        } else {
          // Calculate height based on width and aspect ratio
          width = baseSize;
          height = width * (ratio2 / ratio1);
        }
      }

      console.log(
        `Calculated dimensions: width = ${width}, height = ${height}`
      );

      // Determine position of the image if not provided
      const x = (pos?.x || store.width / 2) - width / 2;
      const y = (pos?.y || store.height / 2) - height / 2;

      // Add the image to the store with the calculated dimensions
      store.history.transaction(async () => {
        const img = store.activePage?.addElement({
          type: "image",
          src: url,
          width: width,
          height: height,
          x: x,
          y: y,
        });

        if (isAlive(img)) {
          await img.set({ src: url });
        }
      });
    };

    const PhotosContainer = styled('div')`
    height: 100%;
    overflow: hidden;
`;


        // const handleSelect = async (item, pos, element) => {
        //     // console.log(item)
        //     // const { urls, links } = item;
        //     // console.log(urls)
        //     // const { width, height } = await getImageSize(item);
        //     const baseSize = store.height / 2; // Use half of the store's height as the base size
        //     const ratio1 = parseInt(selectRatio.split(":")[0]); // e.g., 16
        //     const ratio2 = parseInt(selectRatio.split(":")[1]); // e.g., 9
        //     let width, height;

        //     if (ratio1 >= ratio2) {
        //         // Calculate width based on height and aspect ratio
        //         height = baseSize;
        //         width = height * (ratio1 / ratio2);
        //     } else {
        //         // Calculate height based on width and aspect ratio
        //         width = baseSize;
        //         height = width * (ratio2 / ratio1);
        //     }
        //     const imageSrc = { src: item };


        //     const sizes = store.height / 2;
        //     if (element && element.type === 'image' && element.contentEditable) {
        //         const crop = getCrop(element, { width, height });
        //         element.set(Object.assign(imageSrc, crop));
        //         return;
        //     }

        //     // const x = (pos?.x || store.width / 2) - width / 2;
        //     // const y = (pos?.y || store.height / 2) - height / 2;

        //     store.history.transaction(async () => {
        //         const img = store.activePage?.addElement({ type: 'image', src: item, width: sizes,
        //                height: sizes,x: 20,
        //                y: 20, });
        //     });
        // };
        return (
          // {/ <PhotosContainer> /}
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '10px' }}>

        
        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              ImageGenerate(prompt, selected, imageSize);
            }}
          >
            <div className="px-0 py-1 flex items-center w-full justify-between">
         

              <TextArea
                className="w-full"
                type="text"
                leftIcon="search"
                placeholder={"Generate image"}
                style={{ height: "60px" }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Prevent form submission if necessary
                    ImageGenerate(prompt, selected, imageSize); // Trigger form submission manually
                  }
                }}
                // value={prompt} // set value of the input to the state
                onChange={(event) => setPrompt(event.target.value)}
              />
               
            </div>
            {promptError && (
              <div style={{ color: "red", marginBottom: "10px" }}>
                {promptError}
              </div>
            )}
            <div
              className="w-full justify-between mt-1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: "10px" }}>Variation</div>
              <HTMLSelect
                style={{
                  width: "80px",
                  appearance: "none", // Hides the default arrow
                  WebkitAppearance: "none", // For Safari and Chrome
                  MozAppearance: "none", // For Firefox
                  background: "none", // Removes the background that may contain the arrow
                  
                 // Optional: Adjust padding as needed
                }} // Added styles to remove arrows

                value={selected}
                onChange={handleChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </HTMLSelect>
            </div>

            <div
              className="w-full justify-between mt-2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: "10px" }}>Aspect Ratio</div>
              <HTMLSelect
                style={{
                  width: "80px",
                  appearance: "none", // Hides the default arrow
                  WebkitAppearance: "none", // For Safari and Chrome
                  MozAppearance: "none", // For Firefox
                  background: "none", // Removes the background that may contain the arrow
              
                  // Optional: Adjust padding as needed
                }}
                value={selectRatio}
                onChange={handleChangeRatio}
              >
                <option value="1:1">1:1</option>
                <option value="16:9">16:9</option>
                <option value="21:9">21:9</option>
                <option value="2:3">2:3</option>
                <option value="3:2">3:2</option>
                <option value="4:5">4:5</option>
                <option value="5:4">5:4</option>
                <option value="9:16">9:16</option>
                <option value="9:21">9:21</option>
              </HTMLSelect>
            </div>
            {/* <div
              className=" justify-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: "10px" }}>Size</div>
              <HTMLSelect
                className="mt-2 "
                value={imageSize}
                onChange={(e) => setImageSize(e.target.value)}
                  style={{
                  appearance: "none", // Hides the default arrow
                  WebkitAppearance: "none", // For Safari and Chrome
                  MozAppearance: "none", // For Firefox
                  background: "none", // Removes the background that may contain the arrow
                 // Optional: Adjust padding as needed
                }}
              >
                <option value="512x512">512 x 512</option>
                <option value="1024x1024">1024 x 1024</option>
              </HTMLSelect>
            </div> */}

            <Button
              className="mt-2 w-fit"
              fill
              type={"submit"}
              intent="primary"
              // onClick={() => ImageGenerate(prompt, selected, imageSize)}
            >
              Generate
            </Button>
          </form>
        </div>

        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spinner />
          </div>
        ) : (
       
           
            <ImagesGrid
              className="w-full h-full grid grid-cols-2 md:grid-cols-3 gap-4 mt-5"  
              shadowEnabled={false}
              images={imageUrls.slice().reverse()} // Reverse the array for LIFO order
              getPreview={(item) => item} // Assuming imageUrls contains the direct image URLs
              isLoading={isLoading} // Pass the isLoading state
              onSelect={(url, pos, element) =>
                handleImageClick(url, pos, element)
              } // Pass the element for cropping
              rowsNumber={2} // Set the number of rows as 2
              // loadMore={hasMore && loadMore} // Load more images if applicable
              // error={error}
              getImageStyle={() => ({
                width: "100px", // Increase image width
                height: "100px", // Increase image height
              })}
            />
            

          
         
        )}
        {/* {/ {/ </PhotosContainer> /} /} */}
        </div>
    );
  }),
};
