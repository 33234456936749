import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { resetPassword1 } from "../APIservice/apiService";
import { useParams } from "react-router-dom";
import { Spinner } from "flowbite-react";
import Cookies from "js-cookie";

const Reset = () => {
  const navigate = useNavigate();
  const gologin = () => {
    navigate("/login");
  };
  const { id } = useParams();
  const [formData, setFormData] = useState({
    password: "",
    Cpassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");

  const resetPassword = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const password = formData.password; // Access the password input value
    const confirmPassword = formData.Cpassword;
    const token = id;
    const errors = {};
    const passwordValidations = [
      { condition: !formData.password, message: "Password is required" },
      { condition: formData.password.length < 8, message: "Password must be at least 8 characters long" },
      { condition: !/[A-Z]/.test(formData.password), message: "Password must contain at least one uppercase letter" },
      { condition: !/[a-z]/.test(formData.password), message: "Password must contain at least one lowercase letter" },
      { condition: !/\d/.test(formData.password), message: "Password must contain at least one number" },
      { condition: !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formData.password), message: "Password must contain at least one special character" },
      { condition: formData.password.length > 16, message: "Password must not exceed 16 characters" },
      { condition: !formData.Cpassword, message: "Confirm password is required" },
      { condition: formData.password !== formData.Cpassword, message: "Passwords do not match" }
    ];

    passwordValidations.forEach(validation => {
      if (validation.condition) {
        errors.password = validation.message;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      setIsLoading(true);
      const response = await resetPassword1(confirmPassword, password, token);
      setIsLoading(false);
      if (response.data.message) {
        Cookies.remove("token", { domain: process.env.REACT_APP_TOKEN_DOMAIN }); // Removes the 'token' cookie
        Cookies.remove("user", { domain: process.env.REACT_APP_TOKEN_DOMAIN });
        navigate("/login");
      }
    } catch (error) {
      console.log("Here its coming");
      console.error("Login failed:", error);
      Cookies.remove("token", { domain: process.env.REACT_APP_TOKEN_DOMAIN }); // Removes the 'token' cookie
      Cookies.remove("user", { domain: process.env.REACT_APP_TOKEN_DOMAIN });
      setTimeout(() => navigate("/forgot"), 2000);
      setIsLoading(false);
    }
    event.preventDefault();
  };

  return (
    <div className="min-h-screen bg-white flex">
      {/* Container */}
      <div className="flex flex-row w-full">
        {/* Sidebar */}
        <div className="hidden lg:flex flex-col justify-between bg-black text-white lg:p-8 xl:p-12 lg:max-w-sm xl:max-w-lg">
          <div className="flex items-center justify-start space-x-3">
            <img
              src={require("../assets/1.png")}
              alt="logo"
              className="w-75 h-10 cursor-pointer"
              onClick={() =>
                (window.location.href = process.env.REACT_APP_REDIRECT_URL)
              }
            />
          </div>
          <div className="space-y-5">
            <h1 className="lg:text-3xl xl:text-5xl xl:leading-snug font-extrabold">
              Secure your account with a new password
            </h1>
            <p className="text-lg">Remember your password?</p>
            <button
              onClick={gologin}
              className="inline-block flex-none px-4 py-3 border-2 rounded-lg font-medium border-black bg-white text-black hover:bg-[#4c6ee2] hover:text-white duration-700"
            >
              Back to Login
            </button>
          </div>
          <p className="font-medium">© {new Date().getFullYear()}  – Imageeazy</p>
        </div>

        {/* Reset Password Form */}
        <div className="flex flex-1 flex-col items-center justify-center relative">
          {/* Mobile Logo Header */}
          <div className="flex lg:hidden justify-between items-center w-full py-4 px-10">
            <div className="flex items-center justify-start space-x-3"></div>
          </div>

          <div className="flex flex-1 items-center justify-center px-10 w-full">
            <div className="w-full max-w-md space-y-5">
              <div className="flex items-center justify-center">
                <img
                  src={require("../assets/1.png")}
                  alt="logo"
                  className="w-30 lg:hidden max-w-[75%]"
                  onClick={() =>
                    (window.location.href = process.env.REACT_APP_REDIRECT_URL)
                  }
                />
              </div>
              <div className="">
                <div className="flex flex-col space-y-2 text-center">
                  <h2 className="text-3xl md:text-4xl font-bold">
                    Reset Password
                  </h2>
                  <p className="text-md md:text-xl">
                    Enter your new password below
                  </p>
                </div>
              </div>

              <form
                onSubmit={resetPassword}
                className="flex flex-col max-w-md space-y-5"
              >
                <div>
                  <input
                    type="password"
                    placeholder="New Password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                      setFormErrors({ ...formErrors, password: "" });
                      setPassword(e.target.value);
                    }}
                    className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                  />
                  {/* {formErrors.password && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.password}
                    </p>
                  )} */}
                </div>

                <div>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="Cpassword"
                    value={formData.Cpassword}
                    onChange={(e) => {
                      setFormData({ ...formData, Cpassword: e.target.value });
                      setFormErrors({ ...formErrors, Cpassword: "" });
                      setConfirmPassword(e.target.value);
                    }}
                    className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                  />
                  {formErrors.Cpassword && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.Cpassword}
                    </p>
                  )}
                </div>

                {formErrors.password && (
                    <p className="text-red-500 text-sm">
                      {formErrors.password}
                    </p>
                  )}

                <button
                  type="submit"
                  className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black bg-black text-white hover:bg-[#4c6ee2] hover:border-[#4c6ee2] duration-700"
                >
                  {isLoading ? (
                    <>
                      <Spinner aria-label="Loading..." />
                      <span className="pl-3">Loading...</span>
                    </>
                  ) : (
                    "Reset Password"
                  )}
                </button>
                <ul className="list-none pl-2 mt-1 text-xs md:text-sm text-gray-600 space-y-1">
                  <li className="flex items-center">
                    <span
                      className={`w-[8px] h-2 rounded-full ${
                        formData.password && formData.password.length >= 8
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                    <span className="pl-1 text-sm">
                      Must be at least 8 characters long
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span
                      className={`w-[8px] h-2 rounded-full ${
                        formData.password &&
                        /[A-Z]/.test(formData.password)
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                    <span className="pl-1 text-sm">
                      Must contain at least one uppercase letter
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span
                      className={`w-[8px] h-2 rounded-full ${
                        formData.password && /[a-z]/.test(formData.password)
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                    <span className="pl-1 text-sm">
                      Must contain at least one lowercase letter
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span
                      className={`w-[8px] h-2 rounded-full ${
                        formData.password && /\d/.test(formData.password)
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                    <span className="pl-1 text-sm">
                      Must contain at least one number
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span
                      className={`w-[8px] h-2 rounded-full ${
                        formData.password && /[!@#$%^&*(),.?":{}|<>]/.test(formData.password)
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                    <span className="pl-1 text-sm">
                      Must contain at least one special character
                    </span>
                  </li>

                  <li className="flex items-center">
                    <span
                      className={`w-[8px] h-2 rounded-full ${
                        formData.password && formData.Cpassword &&
                        formData.password === formData.Cpassword &&
                        formData.password.length >= 8 &&
                        /[A-Z]/.test(formData.password) &&
                        /[a-z]/.test(formData.password) &&
                        /\d/.test(formData.password) &&
                        /[!@#$%^&*(),.?":{}|<>]/.test(formData.password)
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                    <span className="pl-1 text-sm">
                      Passwords match and meet all requirements
                    </span>
                  </li>
                </ul>
              </form>
              <div className="flex items-center space-x-1 justify-center lg:hidden">
                <span>Remember password? </span>
                <button
                  onClick={() => navigate("/login")}
                  className="underline font-medium text-[#4c6ee2] hover:text-black duration-200 cursor-pointer"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
