import React, { useState, useEffect, useContext } from "react";
import { Button, Checkbox, Label } from "flowbite-react";
import {
  getProfile,
  updatePassword,
  updateProfile,
} from "../APIservice/apiService";
import Cookies from "js-cookie";
import { nameCharLimit } from "../CharLimit";
import { UserContext } from "../context/UserContext";
import { Spinner } from "flowbite-react";

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { UserName, updateUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  console.log(UserName);
  const userCookie = Cookies.get("user");
  // const user = userCookie ? JSON.parse(userCookie) : {};

  // console.log(user);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const [name, setName] = useState(UserName.name);
  const [email, setEmail] = useState(UserName.email);

  const fetchData = async () => {
    try {
      const response = await getProfile();
      if (response) {
        setName(response.data.data.name);
        updateUser({ name: response.data.data.name });
        setEmail(response.data.data.email);
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setFormData({ ...formData, name: event.target.value });
    setFormErrors({ ...formErrors, name: "" });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setFormData({ ...formData, email: event.target.value });
    setFormErrors({ ...formErrors, email: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // let trimmedName = name.trim();
    console.log("Name:", name);
    console.log("Email:", email);
    const errors = {};
    // if (!formData.email || !name) errors.email = "Email is required";

    // if (!formData.name) errors.name = "Name is required";
    // const nameRegex = /\S/;
    // const nameRegex = /^[A-Za-z](?!.*  )[A-Za-z ]*[A-Za-z]$/;
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;

    const validateEmail = (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    };
    if (!email) {
      errors.email = "Email is required";
    } else if (!validateEmail(email)) {
      errors.email = "Email is invalid";
    }

    if (!name) {
      errors.name = "Name is required";
    } else if (!nameRegex.test(name)) {
      errors.name =
        "Name should not start or end with a space and should contain only alphabetic characters.";
    } else if (name.length > nameCharLimit) {
      errors.name = `Name cannot exceed ${nameCharLimit} characters`;
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    const userCookie = Cookies.get("user");
    try {
      setIsLoading(true);
      const response = await updateProfile(name, email);
      setIsLoading(false);
      updateUser({ name: name, email: email });
      const userData = JSON.parse(userCookie);
      userData.name = response.data.data.name; // Assuming response contains updated name
      userData.email = response.data.data.email; // Assuming response contains updated email
      Cookies.set("user", JSON.stringify(userData), {
        expires: 7,
        domain: process.env.REACT_APP_TOKEN_DOMAIN,
      });

      Cookies.set("wp_user", JSON.stringify(userData), {
        expires: 7,
        domain: process.env.REACT_APP_TOKEN_DOMAIN, // Note the leading dot
        path: "/",
        secure: true,
        sameSite: "Lax",
      });
    } catch (error) {
      console.error("Login failed:", error);
    }
    // Add any further processing logic here
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handlePasswordSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const oldPassword = document.getElementById("cpass").value;
    const newPassword = document.getElementById("newpass").value;
    const confirmPassword = document.getElementById("confirm-password").value;
    const host = "https://platform.imageeazy.com";

    let isValid = true;
    if (oldPassword === "") {
      setPasswordError("Current password is required");
      isValid = false;
    }

    // Validate new password
    if (newPassword === "") {
      setNewPasswordError("New password is required");
      isValid = false;
    } else if (newPassword.length < 8 || newPassword.length > 16) {
      setNewPasswordError("New password must have between 8 and 16 characters");
      isValid = false;
    }

    // Validate confirm password
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required");
      isValid = false;
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    if (isValid) {
      try {
        setIsLoading1(true);
        const response = await updatePassword(oldPassword, newPassword, host);
        setIsLoading1(false);
        if (response) {
          // assuming your response has an isSuccess flag
          // Reset form fields
          document.getElementById("cpass").value = "";
          document.getElementById("newpass").value = "";
          document.getElementById("confirm-password").value = "";
        }
        console.log(response, "response");
      } catch (error) {
        setIsLoading1(false);
        console.error("Login failed:", error);
      }
    }
    // Add any further processing logic here
  };
  const handlePasswordInput = (e) => {
    setOldPassword(e.target.value);
    if (e.target.value !== "") setPasswordError(""); // Clear error if user entered some text
  };

  const handleNewPasswordInput = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value !== "") setNewPasswordError(""); // Clear error if user entered some text
  };

  const handleConfirmPasswordInput = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== "") setConfirmPasswordError(""); // Clear error if user entered some text
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mx-auto py-6 px-4 sm:px-6 lg:px-8 dashboard_h2">
          <h2 className="font-bold text-4xl text-gray-800 dark:text-black leading-tight">
            Profile
          </h2>
        </div>

        <main>
          <div className="pb-6">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6 ">
              <div className="p-4 sm:p-8 bg-white shadow-lg sm:rounded-lg border border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">
                  Profile Information
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Update your account's profile information
                </p>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(event);
                  }}
                  className="flex mt-2 max-w-xl flex-col gap-2"
                >
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="name"
                        value="Name"
                        className="text-black text-semibold dark:text-black  dark:text-semibold"
                      />
                    </div>
                    <input
                      id="name"
                      type="text"
                      onChange={handleNameChange}
                      value={name}
                      placeholder="Name"
                      className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                    />
                    {formErrors.name && (
                      <p className="text-red-500">{formErrors.name}</p>
                    )}
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="email2"
                        value="Your email"
                        className="text-black text-semibold dark:text-black  dark:text-semibold"
                      />
                    </div>
                    <input
                      id="email2"
                      type="email"
                      disabled
                      value={email}
                      placeholder="Email"
                      className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal opacity-70"
                    />
                    {formErrors.email && (
                      <p className="text-red-500">{formErrors.email}</p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 mt-2 rounded-lg font-medium border-black bg-black text-white hover:bg-[#4c6ee2] hover:border-[#4c6ee2] duration-700"
                    fdprocessedid="n5m144"
                  >
                    {isLoading ? (
                      <>
                        <Spinner aria-label="Default status example" />{" "}
                        <span className="pl-3">Saving</span>
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>
                </form>
              </div>
              <div className="p-4 sm:p-8 bg-white shadow-lg sm:rounded-lg border border-gray-200">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                  <div className="mb-4 md:mb-0 md:mr-8">
                    <h2 className="text-lg font-medium text-gray-900">
                      Update Password
                    </h2>
                    <p className="mt-1 text-sm text-gray-600">
                      Ensure your account is using a long, random password to
                      stay secure.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-8">
                  <div className="w-full md:w-1/2">
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        handlePasswordSubmit(event);
                      }}
                      className="flex flex-col gap-4"
                    >
                      <div>
                        <Label
                          htmlFor="cpass"
                          value="Current Password"
                          className="text-black text-semibold dark:text-black dark:text-semibold mb-1 block"
                        />
                        <input
                          id="cpass"
                          type="password"
                          required
                          onChange={handlePasswordInput}
                          placeholder="Current Password"
                          className="w-full px-3 py-2 border-2 border-black rounded-lg"
                        />
                        {passwordError && (
                          <p className="text-red-500 mt-1">{passwordError}</p>
                        )}
                      </div>
                      <div>
                        <Label
                          htmlFor="newpass"
                          value="New password"
                          className="text-black text-semibold dark:text-black dark:text-semibold mb-1 block"
                        />
                        <input
                          id="newpass"
                          type="password"
                          required
                          onChange={handleNewPasswordInput}
                          placeholder="New Password"
                          className="w-full px-3 py-2 border-2 border-black rounded-lg"
                        />
                        {newPasswordError && (
                          <p className="text-red-500 mt-1">
                            {newPasswordError}
                          </p>
                        )}
                      </div>
                      <div>
                        <Label
                          htmlFor="confirm-password"
                          value="Confirm password"
                          className="text-black text-semibold dark:text-black dark:text-semibold mb-1 block"
                        />
                        <input
                          id="confirm-password"
                          type="password"
                          required
                          onChange={handleConfirmPasswordInput}
                          placeholder="Confirm Password"
                          className="w-full px-3 py-2 border-2 border-black rounded-lg"
                        />
                        {confirmPasswordError && (
                          <p className="text-red-500 mt-1">
                            {confirmPasswordError}
                          </p>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex items-center justify-center px-4 py-2 mt-4 border-2 rounded-lg font-medium border-black bg-black text-white hover:bg-[#4c6ee2] hover:border-[#4c6ee2] transition duration-300"
                        onClick={handlePasswordSubmit}
                      >
                        {isLoading1 ? (
                          <>
                            <Spinner aria-label="Updating" />
                            <span className="ml-2">Updating...</span>
                          </>
                        ) : (
                          "Update Password"
                        )}
                      </button>
                    </form>
                  </div>
                  <div className="w-full md:w-1/2 mt-2 md:mt-5">
                    <h3 className="text-lg font-semibold mb-4 ml-5">
                      Password Requirements
                    </h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li className="flex items-center space-x-2">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            newPassword.length >= 8 ? "bg-green-500" : "bg-black"
                          }`}
                        ></span>
                        <span>Minimum 8 characters long</span>
                      </li>

                      <li className="flex items-center space-x-2">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            /[A-Z]/.test(newPassword) ? "bg-green-500" : "bg-black"
                          }`}
                        ></span>
                        <span>At least one uppercase letter</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            /[a-z]/.test(newPassword) ? "bg-green-500" : "bg-black"
                          }`}
                        ></span>
                        <span>At least one lowercase letter</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            /\d/.test(newPassword) ? "bg-green-500" : "bg-black"
                          }`}
                        ></span>
                        <span>At least one number</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            /[!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? "bg-green-500" : "bg-black"
                          }`}
                        ></span>
                        <span>At least one special character</span>
                      </li>

                      <li className="flex items-center space-x-2">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            newPassword && confirmPassword && newPassword === confirmPassword ? "bg-green-500" : "bg-black"
                          }`}
                        ></span>
                        <span>Passwords match</span>
                      </li>
                    </ul>
                    {/* <div className="mt-6">
                      <h4 className="font-medium mb-2">Password Strength</h4>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{ width: "45%" }}
                        ></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Profile;
