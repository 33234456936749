import React, {useEffect, useRef, useState} from 'react';
import { observer } from 'mobx-react-lite';
import {Button, Tooltip, Navbar, Menu, MenuItem, Popover, Spinner} from '@blueprintjs/core';
import { extendToolbar, ElementContainer } from 'raeditor/toolbar/element-container';
import FiltersPicker from 'raeditor/toolbar/filters-picker';
import { AnimationsPicker } from 'raeditor/toolbar/animations-picker';
import FlipButton from 'raeditor/toolbar/flip-button';
import ImageRemoveBackground from './ImageBackgroundRemover';
import { getImageSize, getCrop } from 'raeditor/utils/image';
import { t } from 'raeditor/utils/l10n';
import { flags } from 'raeditor/utils/flags';
import { UpscaleDialog } from './ImageUpscaler';

// Image Fit to Background Component
export const ImageFitToBackground = ({ element }) => (
    <Button
        text={t('toolbar.fitToBackground')}
        minimal
        onClick={async () => {
            const { page } = element;
            const width = page.computedWidth + 2 * page.bleed;
            const height = page.computedHeight + 2 * page.bleed;
            const imageSize = await getImageSize(element.src);
            const crop = getCrop({ width, height }, imageSize);

            element.set({
                x: -page.bleed,
                y: -page.bleed,
                width,
                height,
                rotation: 0,
                ...crop,
            });
        }}
    />
);
const ClipOptionsMenu = ({ element, store }) => (
    <Menu>
        <MenuItem
            text={t('toolbar.change')}
            onClick={(event) => {
                event.stopPropagation();
                store.openSidePanel('image-clip');
            }}
        />
        <MenuItem
            text={t('toolbar.remove')}
            onClickCapture={() => element.set({ clipSrc: '' })}
        />
    </Menu>
);
// Image Clip Component
export const ImageClip = observer(({ element, store }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    if (!element.contentEditable) {
        return null;
    }

    return (
        <Popover
            content={<ClipOptionsMenu element={element} store={store} />}
            isOpen={isOpen}
            onInteraction={(state) => setIsOpen(state)}
            position="bottom"
        >
            <Button
                text={element.clipSrc ? t('toolbar.mask Options') : t('toolbar.mask')}
                minimal
                onClickCapture={(event) => {
                    event.stopPropagation();
                    if (element.clipSrc) {
                        handleToggle();
                    } else {
                        store.openSidePanel('image-clip');
                    }
                }}
            />
        </Popover>
    );
});

// Image Crop Component
export const ImageCrop = observer(({ element }) =>
    element.contentEditable ? (
        <Tooltip content={t('toolbar.crop')} position="bottom">
            <Button
                minimal
                text={t('toolbar.crop')}
                onClickCapture={(event) => {
                    event.stopPropagation();
                    element.toggleCropMode(true);
                }}
            />
        </Tooltip>
    ) : null
);

// Image Remove Clip Component
export const ImageRemoveClip = observer(({ element }) =>
    element.clipSrc ? (
        <Button
            text={t('toolbar.removeClip')}
            minimal
            onClickCapture={() => element.set({ clipSrc: '' })}
        />
    ) : null
);

// Upscale Image Component
export const ImageUpscale = observer(({ element, store }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <Button
                text="Upscale"
                minimal
                icon="arrow-up"
                onClick={() => setIsDialogOpen(true)}
            />
            <UpscaleDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                element={element}
            />
        </>
    );
});

// Property Map for Toolbar Components
const PROPS_MAP = {
    ImageFlip: FlipButton,
    ImageFilters: FiltersPicker,
    ImageFitToBackground,
    ImageCrop,
    ImageClip,
    ImageRemoveBackground,
    ImageAnimations: AnimationsPicker,
    ImageUpscale,
};

// Image Toolbar Component
export const ImageToolbar = observer(({ store, components }) => {
    const element = store.selectedElements[0];
    const isCropModeEnabled = element._cropModeEnabled;
    const previousStateRef = useRef({});

    useEffect(() => {
        if (isCropModeEnabled) {
            previousStateRef.current = {
                x: element.x,
                y: element.y,
                width: element.width,
                height: element.height,
                cropX: element.cropX,
                cropY: element.cropY,
                cropWidth: element.cropWidth,
                cropHeight: element.cropHeight,
            };
        }
    }, [isCropModeEnabled]);

    const toolbarItems = [
        'ImageFlip',
        'ImageFilters',
        'ImageFitToBackground',
        'ImageClip',
        'ImageCrop',
        flags.animationsEnabled && 'ImageAnimations',
        flags.removeBackgroundEnabled && 'ImageRemoveBackground',
        'ImageUpscale',
    ].filter(Boolean);

    const extendedToolbar = extendToolbar({ type: 'image', usedItems: toolbarItems, components });

    return (
        <>
            {!isCropModeEnabled && (
                <ElementContainer
                    items={extendedToolbar}
                    itemRender={(item) => {
                        const Component = components[item] || PROPS_MAP[item];
                        return <Component key={item} element={element} store={store} />;
                    }}
                >
                    <ImageFitToBackground element={element} />
                    <ImageUpscale element={element} store={store} />
                </ElementContainer>
            )}
            {isCropModeEnabled && (
                <Navbar.Group>
                    <Button
                        text={t('toolbar.cropDone')}
                        minimal
                        icon="tick"
                        onClickCapture={() => element.toggleCropMode(false)}
                    />
                    <Button
                        text={t('toolbar.cropCancel')}
                        minimal
                        icon="cross"
                        onClickCapture={() => {
                            element.set({ ...previousStateRef.current });
                            element.toggleCropMode(false);
                        }}
                    />
                </Navbar.Group>
            )}
        </>
    );
});

export default ImageToolbar;
