import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA6Qg0k70MZpq_ubtt7QgrHFeph9KQWVgQ",
  authDomain: "imageeazy.firebaseapp.com",
  projectId: "imageeazy",
  storageBucket: "imageeazy.firebasestorage.app",
  messagingSenderId: "483926732570",
  appId: "1:483926732570:web:60e1518861fbecbd8b86a3",
  measurementId: "G-E7914CZTL8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
