import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem } from "flowbite-react";
import { useParams } from "react-router-dom";
import { generateCode } from "../APIservice/apiService";
import * as api from "../api";

const Integration = () => {
  const [token, setToken] = useState(localStorage.getItem("apiToken"));
  const [templateData, setTemplateData] = useState(null);
  const { id } = useParams();
  const [selectedMethod, setSelectedMethod] = useState("REST API Example");
  const [page, setPages] = useState("Page 1");

  // Custom styles for dropdown
  const dropdownStyle = {
    backgroundColor: "#1a1a1a",
    color: "white",
    border: "1px solid #333",
    borderRadius: "0.5rem",
    padding: "0.5rem",
  };

  const dropdownItemStyle = {
    backgroundColor: "#1a1a1a",
    color: "white",
    hover: {
      backgroundColor: "#333",
    },
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await generateCode();
        localStorage.setItem("apiToken", token);
        setToken(token);
      } catch (error) {
        console.error("Failed to generate code:", error);
      }
    };
    const fetchData = async () => {
      let { storeJSON } = await api.loadByUUID({ id });
      if (storeJSON) {
        if (typeof storeJSON === "string") {
          storeJSON = JSON.parse(storeJSON);
        }
        setTemplateData(storeJSON);
      }
    };
    fetchData();
    if (!token) {
      fetchToken();
    }
  }, [id, token]);

  const copyToClipboard = (text) => {
    // text = text.replace(/[',"]/g, "");
    // Remove only the starting and ending quotes
    text = text.replace(/^"|"$/g, "");
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(text, "copy text");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const generatePayload = () => {
    if (!templateData || !templateData.pages) return {}; // Return empty object if no data

    const dataFieldElements = [];
    templateData.pages.forEach((page, index) => {
      page.children.forEach((element) => {
        if (element.custom?.dataField) {
          //   dataFieldElements.push(element);
          dataFieldElements.push({ ...element, pageNumber: index + 1 }); // Add pageNumber to the element
        }
      });
    });

    console.log(dataFieldElements, "Data Field");

    if (dataFieldElements.length === 0) return {}; // Return empty object when no elements found

    const payload = dataFieldElements.reduce((acc, element) => {
      if (element.type === "text") {
        acc[element.name] = { text: element.text };
      } else if (element.type === "image" || element.type === "svg") {
        if (element.custom?.isRatingStar === true) {
          acc[element.name] = { value: element.custom?.value };
        } else {
          acc[element.name] = { src: element.src };
        }
      }
      return acc;
    }, {});

    return payload;
  };

  const generatePayloadforUrl = (selectedPage) => { // Accept selectedPage as a parameter
    if (!templateData || !templateData.pages) return {}; // Return empty object if no data

    const dataFieldElements = [];
    templateData.pages.forEach((page, index) => {
      page.children.forEach((element) => {
        if (element.custom?.dataField) {
          dataFieldElements.push({ ...element, pageNumber: index + 1 }); // Add pageNumber to the element
        }
      });
    });

    // Filter dataFieldElements based on the selected page number
    const filteredElements = dataFieldElements.filter(element => element.pageNumber === selectedPage);

    if (filteredElements.length === 0) return {}; // Return empty object when no elements found

    const payload = filteredElements.reduce((acc, element) => {
      if (element.type === "text") {
        acc[element.name] = { text: element.text };
      } else if (element.type === "image" || element.type === "svg") {
        if (element.custom?.isRatingStar === true) {
          acc[element.name] = { value: element.custom?.value };
        } else {
          acc[element.name] = { src: element.src };
        }
      }
      return acc;
    }, {});

    return payload;
  };

  const formatUrlSchema = (payload) => {
    return Object.entries(payload)
      .map(([key, value]) => {
        const innerKey = Object.keys(value)[0];
        const innerValue = Object.values(value)[0];
        return `${key}=${innerValue}`;
      })
      .join("&");
  };

  const codeMethods = {
    "REST API Example": {
      "AUTHENTICATION HEADERS": {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      ENDPOINT: `POST ${process.env.REACT_APP_GENERATE_URL}api/templates/images/${id}`,
      "REST API JSON PAYLOAD": generatePayload(),
    },
    "URL API Example": {
      URL: `${process.env.REACT_APP_GENERATE_URL}api/templates/cij/${id}`,
      "URL SCHEMA": `${
        process.env.REACT_APP_GENERATE_URL
      }api/templates/cij/${id}?${formatUrlSchema(generatePayload())}`,
    // "URL SCHEMA": `${
    //     process.env.REACT_APP_GENERATE_URL
    //   }api/templates/cij/${id}${templateData?.pages.length > 1 ? `?${page.toLowerCase().replace(/ /g, '=')}` : ''}&${formatUrlSchema(generatePayloadforUrl(parseInt(page.split(' ')[1])))}`,

    },
  };

  const handleChange = (method) => {
    setSelectedMethod(method);
  };

  const handlePages = (method) => {
    setPages(method);
  };

  const example = codeMethods[selectedMethod];
  console.log(templateData, "Response from UUID");

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
      <div className="max-w-7xl mt-10 flex justify-between items-center mx-auto px-4 sm:px-6 lg:px-8 ">
        <div
          onClick={() => window.history.back()}
          className="cursor-pointer flex items-center text-black "
        >
          <svg
            className="w-6 h-6 mr-2" // Adjust size and margin as needed
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12H3m0 0l6-6m-6 6l6 6"
            />
          </svg>
          {/* <span className="font-bold">Back</span> */}
        </div>
        <h1 className="text-black text-3xl font-bold text-center flex-grow pb-4">
          API Integration
        </h1>
      </div>
      <div className="text-center w-full mt-2 flex justify-center shadow sm:p-8 px-5 bg-white flex-grow rounded-lg mb-8">
        <div className="container  p-4 rounded-lg mb-4">
          <Dropdown
            label={selectedMethod}
            dismissOnClick={true}
            style={{
              width: "100%",
              backgroundColor: "#1a1a1a",
              color: "white",
              border: "none",
              fontSize: "20px",
              justifyContent: "left",
              marginBottom: "10px",
              marginTop: "14px",
              borderRadius: "0.5rem",
            }}
          >
            <DropdownItem
              onClick={() => setSelectedMethod("REST API Example")}
              className="block px-4 py-2 text-sm text-black font-semibold w-full text-left dark:text-white dark:hover:bg-black dark:hover:text-white"
            >
              REST API Example
            </DropdownItem>
            <DropdownItem
              onClick={() => setSelectedMethod("URL API Example")}
              className="block px-4 py-2 text-sm text-black font-semibold w-full text-left"
            >
              URL API Example
            </DropdownItem>
          </Dropdown>

          {Object.entries(example).map(([key, value]) => (
            <div
              key={key}
              className="bg-white border border-gray-200 p-5 rounded-lg shadow-lg mb-2"
            >
              <div className="mb-4">
                <div className="flex justify-between">
                  <h4 className="text-base text-left font-bold text-gray-900 mb-2">
                    {key}
                  </h4>
                  <button
                    onClick={() =>
                      copyToClipboard(JSON.stringify(value, null, 2))
                    }
                    type="button"
                    className="text-white bg-black hover:bg-gray-800 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                  >
                    <svg
                      className="w-4 h-4 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <pre className="bg-gray-50 text-left text-gray-900 p-3 rounded-md overflow-x-auto text-base">
                  {key === "REST API JSON PAYLOAD" &&
                  Object.keys(value).length === 0
                    ? "You have not selected any data."
                    : JSON.stringify(value, null, 2)}
                </pre>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Integration;